import React, {Fragment, useState,useRef, useEffect} from 'react';
import {Rnd} from 'react-rnd';
import {useSelector} from 'react-redux';
import {firebaseApp, realTimeDb,firebaseFunctions,db} from '../../firebase'
import { useCollectionData } from 'react-firebase-hooks/firestore';

import "./tailwind.css"
import './Chat.css';
//import './ChatRoom.scss';



const ChatRoom = ({userSettings}) => {
   
    const user = firebaseApp.auth().currentUser;
    const {uid} = user;
    let displayName = userSettings.displayName         
    const photoURL = 'https://ui-avatars.com/api/?name=' + displayName 
    const auth = firebaseApp.auth();

  const iframeDefaulSizeParams = {
    defaultWidth: 400,
    defaultHeight: 600,
    minWidth: 200,
    minHeight: 400,
    chatHeaderHeight: 25, // chat header height
  }

  const [showChat, setShowChat] = useState(false);
  const [loadedChat, setLoadedChat] = useState(false);
  const [loading, setLoading] = useState(false);
  const [iframeWidth, setIframeWidth] = useState(iframeDefaulSizeParams.defaultWidth);
  const [iframeHeight, setIframeHeight] = useState(iframeDefaulSizeParams.defaultHeight);
  
  function onResizeChat() {
    const changedWidth = document.getElementById('chat-wrapper-draggable').offsetWidth;
    const changedHeight = document.getElementById('chat-wrapper-draggable').offsetHeight;

    setIframeWidth(changedWidth);
    setIframeHeight(changedHeight - iframeDefaulSizeParams.chatHeaderHeight);
  }
  
  //console.log(db.FieldValue.serverTimestamp())

  
   
  return (
    <Fragment>
      <input type="checkbox" id="click" />
      <label for="click">
      <i class="fab fa-facebook-messenger"></i>
      <i class="fas fa-times"></i>
      </label>
      <div class="wrapper">
         <div class="head-text">
            Let's chat!
            
         </div>
         <div class="chat-box">
          <section className="">
            {user ? <ChatRoom /> : null}
          </section>
         </div>
      </div>

   </Fragment>

  )

function ChatRoom() {
  
  const dummy = useRef();  
  const scrollToBottom = () => {
    dummy.current.scrollIntoView({ behavior: 'smooth' });
  }
  
  const messagesRef = db.collection('messages');
  const query = messagesRef.orderBy('createdAt').limitToLast(25);

  const [messages] = useCollectionData(query, { idField: 'id' });

  const [formValue, setFormValue] = useState('');
  
  useEffect(scrollToBottom, [messages]);


  const sendMessage = async (e) => {
    e.preventDefault();

    await messagesRef.add({
      user: displayName,
      text: formValue,
      //createdAt: db.FieldValue.serverTimestamp(),
      createdAt: new Date(),
      uid,
      photoURL
    })

    setFormValue('');
    dummy.current.scrollIntoView({ behavior: 'smooth' });
  }

  return (
    <div className="">
      <div className="messages overflow-y-auto">
        {messages && messages.map(msg => <ChatMessage key={msg.id} message={msg} />)}
        <span ref={dummy}></span>
      </div>
      <form onSubmit={sendMessage} className="inline-flex">
        <input className="outline-none focus:shadow" value={formValue} onChange={(e) => setFormValue(e.target.value)} placeholder="Say something" />
        <button className={`p-2 mx-2 bg-white rounded-full transition-all duration-75 ease-in-out text-xl ${!formValue || 'text-pink-700 hover:text-pink-900'}`} type="submit" disabled={!formValue}>send</button>
      </form>
    </div>
  )
}


function ChatMessage(props) {
  const { user,text, uid, photoURL } = props.message;

  //const messageClass = uid === auth.currentUser.uid ? 'sent' : 'received';

  const messageClass = uid === auth.currentUser.uid ? 'flex-row-reverse' : 'flex-row';
  const messageBodyClass = uid === auth.currentUser.uid ? 'sent-message-bg text-right' : 'received-message-bg';
  const imageClass = uid === auth.currentUser.uid ? 'ml-2' : 'mr-2';

  return (
    <div className={`px-3 py-2 flex no-wrap items-start ${messageClass}`}>
      <div>
        <img className={`block rounded-full object-cover w-10 ${imageClass}`} src={photoURL || 'https://i.imgur.com/rFbS5ms.png'} alt="{user}'s pfp" />
      </div>
      <div className={`block w-80 break-words p-2 rounded-md ${messageBodyClass}`}>
        <p className="text-xs">{user}</p>
        <p>{text}</p>
      </div>
    </div>
)

  return (<>
    <div className={`message ${messageClass}`}>
      <img src={photoURL} />
      <p>{text}</p>
    </div>
  </>)
}

}

export default ChatRoom;
