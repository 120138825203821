import React, {Fragment, useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Redirect, withRouter} from 'react-router';
import Card from 'react-credit-cards';
import {firebaseApp, db} from '../../firebase';
import {setUserPaid} from '../../store/reducers/UserReducer';
import {ENTRANCE_TYPE} from '../../constants';
import {
  formatCreditCardNumber,
  formatCVC,
  formatExpirationDate,
} from './utils';

import './Payment.css';
import 'react-credit-cards/es/styles-compiled.css';

const Payment = ({history}) => {
  const {email, displayName, userPaid, userPaidForVIP, entranceType} = useSelector(state => state.user);
  const [number, setNumber] = useState('');
  const [name, setName] = useState('');
  const [expiry, setExpiry] = useState('');
  const [cvc, setCvc] = useState('');
  const [activeInput, setActiveInput] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorPayment, setErrorPayment] = useState('');
  const [amountToPay, setAmountToPay] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    const unsubscribe = db.collection('amounts')
      .limit(1).onSnapshot((snapshot) => {
        snapshot.forEach((doc) => {
          const data = doc.data();

          setAmountToPay(data[entranceType])
        })
      });

    return () => {
      unsubscribe()
    }
  }, []);

  const handleInputChange = (e) => {
    setErrorPayment('');

    let value = e.target.value;
    if (activeInput === 'number') {
      value = formatCreditCardNumber(value);
      setNumber(value);
    } else if (activeInput === 'name') {
      setName(value);
    } else if (activeInput === 'expiry') {
      value = formatExpirationDate(value);
      setExpiry(value);
    } else if (activeInput === 'cvc') {
      value = formatCVC(value);
      setCvc(value);
    }
    return;
  };

  const handleSubmit = async () => {
    if (!expiry || !number || !cvc || !name) {
      return
    }

    setErrorPayment('');
    setLoading(true);
    const uid = firebaseApp.auth().currentUser.uid;
    const isVIP = entranceType === ENTRANCE_TYPE.VIP;

    const cardData = {
      exp_month: expiry.split('/')[0],
      exp_year: expiry.split('/')[1],
      number,
      cvc,
      name
    };

    db.collection('payments').add({
      card: cardData,
      uid,
      createdAt: new Date().toISOString()
    })
      .then((docRef) => {
        const unsubscribe = db.collection('payments').doc(docRef.id)
          .onSnapshot((snapshot) => {
            const data = snapshot.data();
            if (data.error) {
              setErrorPayment(data.error);
              setLoading(false);
              unsubscribe();
              console.log(data.error);
            }

            if (data.response && data.response.status === 'succeeded') {
              unsubscribe();
              db.collection('users').doc(uid).set({
                userPaid: true,
                transactionId: data.response.id,
                userPaidForVIP: isVIP
              }, {merge: true}).then(() => {
                dispatch(setUserPaid(true, isVIP));
                history.replace(isVIP ? '/thank-you' : '/stream')
              });
            }
          })
      })
      .catch((error) => {
        setErrorPayment(error);
        setLoading(false);
        console.log('Error create payment: ', error)
      });
  };

  if (userPaid) {
    if(userPaidForVIP) {
      return <Redirect to='/thank-you'/>
    } else {
      return <Redirect to='/stream'/>
    }
  }

  return (
    <div className='payment-content'>

      <div key='Payment' className='payment-wrapper'>
        <div className='payment-header'>
          <div>Hola {displayName || email},</div>
          {amountToPay ?
            (<Fragment>
              <div>Ticket: $ {(amountToPay / 100).toFixed(2)}</div>
            </Fragment>)
            : (<div>this is your payment page</div>)}
        </div>

        <div className="App-payment">
          <Card
            number={number}
            name={name}
            expiry={expiry}
            cvc={cvc}
            focused={activeInput}
          />
          <form>
            <div className="form-group">
              <input
                type="tel"
                name="number"
                value={number}
                className="form-control"
                placeholder="Card Number"
                pattern="[\d| ]{16,22}"
                required
                onChange={(e) => handleInputChange(e)}
                onFocus={() => setActiveInput('number')}
              />
            </div>
            <div className="form-group">
              <input
                type="text"
                name="name"
                value={name}
                className="form-control"
                placeholder="Name"
                required
                onChange={(e) => handleInputChange(e)}
                onFocus={() => {
                  setActiveInput('name')
                }}
              />
            </div>
            <div className="row">
              <div className="col-6">
                <input
                  type="tel"
                  name="expiry"
                  value={expiry}
                  className="form-control"
                  placeholder="Valid Thru"
                  pattern="\d\d/\d\d"
                  required
                  onChange={(e) => handleInputChange(e)}
                  onFocus={() => {
                    setActiveInput('expiry')
                  }}
                />
              </div>
              <div className="col-6">
                <input
                  type="tel"
                  name="cvc"
                  value={cvc}
                  className="form-control"
                  placeholder="CVC"
                  pattern="\d{3,4}"
                  required
                  onChange={(e) => handleInputChange(e)}
                  onFocus={() => {
                    setActiveInput('cvc')
                  }}
                />
              </div>
            </div>

            <div className="form-actions">
              {loading ? (
                <div className='loading'/>
              ) : (
                <button disabled={loading}
                        onClick={handleSubmit}>Comprar
                </button>
              )}
            </div>

            {errorPayment ? (
              <div className='error-payment'>
                <div className='warning-wrapper'>
                  <div className='warning-icon'/>
                </div>
                <div className='error-text'>- {errorPayment}</div>
              </div>
            ) : null}

          </form>
        </div>
      </div>
    </div>
  )
};

export default withRouter(Payment);
