import React from 'react';
import {withRouter} from 'react-router';
import {useDispatch} from 'react-redux';
import {setEntranceType} from '../../store/reducers/UserReducer';
import {ENTRANCE_TYPE} from '../../constants';
import { Link ,useHistory} from 'react-router-dom'
import './Home.css';

const Home = ({history}) => {
  const dispatch = useDispatch();
  const { push } = useHistory()

  function selectEntrance(type) {
    dispatch(setEntranceType(type))
    history.push('/login')
  }

 return(
    <div className='home-content'>
      <div className='dialog-wrapper'>
        <div className='logo-wrapper'>
          <div className='home-logo'/>
        </div>

        <div className='home-header'>
          The virtual venue. Feel the front row experience!
        </div>

        <div className='home-description'>
          shows - festivals - concerts - parties - entertainment
        </div>

        <div className='home-buttons'>
          
         <a href="https://livestreamplanet.com">
          <button className='vip'>Home</button>
          </a>
  
          <button className='general' onClick={() => selectEntrance(ENTRANCE_TYPE.GENERAL)}>SIGN IN</button>
        </div>
      </div>
    </div>
  )
}

export default withRouter(Home);
