import React from 'react';
import {Route, Redirect} from 'react-router';

const ProtectedRoute = ({authenticated, component: Component, ...rest}) => {

  return (<Route
    {...rest}
    render={props =>
      authenticated ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: '/'
          }}
        />
      )
    }
  />)
};

export default ProtectedRoute;
