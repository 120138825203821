import React from 'react';
import {withRouter} from 'react-router';
import {useSelector} from 'react-redux';
import './ThankYou.css';

const ThankYou = ({history}) => {
    const {userPaid, userPaidForVIP} = useSelector(state => state.user);

    if (!userPaid) {
        history.replace('/payment')
    }

    if (!userPaidForVIP) {
        history.replace('/stream');
    }else{
         history.replace('/streamvip');
    }

    return (
        <div className='thank-you-content'>
            <div className='thank-you-header'>Thank you! See you soon.</div>
            <div className='thank-you-description'>An email with all the event info was sent to you.</div>
            <div className='thank-you-description'>This event is scheduled for the 20th of October at 20:20</div>
            {/*<div className='back-to-site'>Back to site</div>*/}
            {/*<div className='add-to-calendar-button'>ADD TO CALENDAR</div>*/}
        </div>
    )
}

export default withRouter(ThankYou);
