const SET_USER_INFO = 'SET_USER_INFO';
const SET_USER_PAID = 'SET_USER_PAID';
const SET_ENTRANCE_TYPE = 'SET_ENTRANCE_TYPE';
const SET_ENTRANCE_PARAMS = 'SET_ENTRANCE_PARAMS';

const initialState = {
    email: '',
    displayName: '',
    userLoggedIn: false,
    userPaid: false,
    userPaidForVIP: false,
    entranceType: '',
    vipInvitationSent: false
};

function setUserInfo(payload) {
    return {
        type: SET_USER_INFO,
        payload
    }
}

function setUserPaid(paid, paidVIP) {
    return {
        type: SET_USER_PAID,
        payload: {paid, paidVIP}
    }
}

function setEntranceType(payload) {
    return {
        type: SET_ENTRANCE_TYPE,
        payload
    }
}
function setEntranceParams(slug, event_code) {
    return {
        type: SET_ENTRANCE_PARAMS,
        payload: {slug, event_code}
    }
}

export {
    setUserInfo,
    setUserPaid,
    setEntranceType,
    setEntranceParams
}

const ACTION_HANDLERS = {
    [SET_USER_INFO]: (state, action) => {

        const {email,
            displayName,
            userLoggedIn,
            userPaid = false,
            userPaidForVIP = false,
            entranceType,
            vipInvitationSent,
            admin = false
        } = action.payload;
        return {...state, email, displayName, userLoggedIn, userPaid, userPaidForVIP, entranceType, vipInvitationSent, admin}
    },
    [SET_USER_PAID]: (state, action) => {
        const {paid, paidVIP} = action.payload;
        return {...state, userPaid: paid, userPaidForVIP: paidVIP}
    },
    [SET_ENTRANCE_TYPE]: (state, action) => {
        return {...state, entranceType: action.payload}
    },
    [SET_ENTRANCE_PARAMS]: (state, action) => {
        const {slug, event_code} = action.payload;
        return {...state, slug: slug, event_code: event_code}
    },
};

export default function UserReducer(state = initialState, action) {
    const handler = ACTION_HANDLERS[action.type];
    return handler ? handler(state, action) : state
}

