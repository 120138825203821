import {createStore, compose} from 'redux';
import {devToolsEnhancer} from 'redux-devtools-extension';
import {rootReducer} from './reducers';

const initialState = {};

const enhancers = [devToolsEnhancer()];

const composedEnhancers = compose(
    ...enhancers,
);

export const store = createStore(rootReducer, initialState, composedEnhancers);
