import React, {Fragment, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import ChatRoom from "../ChatRoom/ChatRoom";
import ShareLink from "../ShareLink/ShareLink";
import {withRouter} from 'react-router';
import {db,firebaseApp,realTimeDb} from '../../firebase'
import './Stream.css'

const Stream = ({history}) => {
  let { displayName, email,entranceType } = useSelector(state => state.user);
  const {userPaid, userPaidForVIP} = useSelector(state => state.user);
  const [streamSettings, setStreamSettings] = useState({});
  const [userSettings, setUserSettings] = useState({});
  const user = firebaseApp.auth().currentUser;
  const {uid} = user;
  let eventInfo
  let iframeUID

  

  useEffect(() => {
     const unsubscribe = db.collection('users').doc(uid).get().then((userSnapshot) => {
        if(userSnapshot.exists){
            setUserSettings(userSnapshot.data());
        }else{
            history.replace('/not-found');
        }      
    })

  }, []);
  


  if (!userPaid) {
    history.replace('/login')
  }

  if (userPaidForVIP) {
    history.replace('/streamvip');
  }
  
  if (!userSettings.streaming_id || userSettings.streaming_id == '') {
    return (<div style={{height: '100%'}}>
            <div className='thank-you-content'>
                <div className='thank-you-header'>Thank you! See you soon.</div>
            </div>
    </div>)
  }
 
  iframeUID = userSettings.streaming_id
  iframeUID = iframeUID.split('_').join('/')
  

  //const iframeLink = '//iframe.dacast.com/b/' + iframeUID;
  const iframeLink = userSettings.iframe;
  userSettings.chatLink = "https://www.enterdev.com/"

  const iframe = `<iframe src="${iframeLink}"  frameBorder="0" allow="autoplay; fullscreen"
                    allowFullScreen webkitallowfullscreen mozallowfullscreen oallowfullscreen msallowfullscreen style="position:absolute;top:0;left:0;
                    right: 0;bottom: 0;margin: auto;width:100%;height:100%;" scrolling="no"></iframe>`;

  return (
    <Fragment>
      <div className='stream-wrapper'>
        <div className='iframe-wrapper'
             dangerouslySetInnerHTML={{__html: iframe}}>
        </div>

      </div>
      
      <ChatRoom userSettings={userSettings}/>
      {/*
      {streamSettings.chatLink ? (<Chat link={streamSettings.chatLink}/>) : null}
      {streamSettings.shareLink ? (<ShareLink link={streamSettings.shareLink}/>) : null}
      */}
      
    </Fragment>
  )
}

export default withRouter(Stream)
