import React, {useRef, useState} from 'react';
import _ from 'lodash';
import {useFormik} from 'formik';
import {withRouter} from 'react-router';
import {useDispatch, useSelector} from 'react-redux';
import {Button, Form, Popup, Ref} from 'semantic-ui-react';
import { firebaseApp, db, realTimeDb} from '../../firebase';
import {setUserInfo} from '../../store/reducers/UserReducer';
import {LoginSchema} from './ValidationSchema';
//import VerifyEmailForm from '../VerifyEmailForm/VerifyEmailForm';
//import NotFoundEvent from '../NotFoundEvent/NotFoundEvent';
//import {ENTRANCE_TYPE} from '../../constants';
//import {setUserPaid} from '../../store/reducers/UserReducer';
import './LogIn.css';

const LogIn = ({history}) => {
  const [loading, setLoading] = useState(false);
  const [authError, setAuthError] = useState({});
  //const [sendVerifyEmail, setSendVerifyEmail] = useState(false);
  const [userSignedInError, setUserSignedInError] = useState(false);
  //const [userWithoutEmail, setUserWithoutEmail] = useState(false);
  const {entranceType, event_code} = useSelector(state => state.user);
  
  const dispatch = useDispatch();
  
  console.log("event_code=" + event_code)
  
 
  const loginButtonRef = useRef();

  const LOGIN_ERROR_TYPE = 'loginError';

  const formik = useFormik({
    initialValues: {
      name: '',
      //password: ''
      password: (event_code)?event_code:''
    },
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: LoginSchema
  });

   function redirectUser(displayName, provider) {
    const user = firebaseApp.auth().currentUser;
    const {uid, email} = user;
    const {name, password} = formik.values;
    const userRef = db.collection('users').doc(uid);


    userRef.set({
      email,
      displayName:name,
      provider,
      lastUpdate:Date.now(),  
      ticketId: password.trim(),
      entranceType
    }, {merge: true});

    const userRealtimeRef = realTimeDb.ref('users/' + uid);

    userRealtimeRef.once('value').then((snapshot) => {
      const isUserOnline = (snapshot.val() && snapshot.val().online) || false;

      userRef.get()
        .then((userSnapshot) => {
          const userData = userSnapshot.data();
          dispatch(setUserInfo({
            ...userData,
            userLoggedIn: true,
            entranceType            
          }))
 
          if (userData) {
            if (userData.event == 'not found') {
                history.push('/not-found')
            } else if (isUserOnline) {
                setUserSignedInError(true);
                setTimeout(() => setUserSignedInError(false), 4000);
                
                realTimeDb.ref('users/' + uid).onDisconnect().set({online: false})
                /**
                firebaseApp.auth().signOut()
                    .then(function() {
                      // Sign-out successful.
                      console.log("logout")
                    })
                    .catch(function(error) {
                      // An error happened
                });
                **/
                 
                
            } else if (userData.userPaid) {
              userRealtimeRef.set({online: true});
              if (user.userPaidForVIP) {
                //history.push('/thank-you')
                 //history.push('/streamvip')
  
              } else {
                history.push('/stream')
              }
            } else {
              userRealtimeRef.set({online: false})
              history.push('/login')
            }
          } else {
            userRealtimeRef.set({online: false})
            history.push('/login')
          }
        })
    })
  }
  
  
  async function loginThroughTicket() {
    setLoading(true);
    setAuthError({});
    const {name, password} = formik.values;
    const validation = await formik.validateForm();

    if (Object.keys(validation).length) {
      setLoading(false);
      return;
    }
    
    let email = password.trim() + '@livestreamplanet.com';

    firebaseApp.auth().signInWithEmailAndPassword(email, password)
      .then(() => {
        redirectUser('', 'email');
      })
      .catch((err) => {
        if (err.code === 'auth/user-not-found') {
          firebaseApp.auth().createUserWithEmailAndPassword(email, password)
            .then(() => {
              const user = firebaseApp.auth().currentUser;           

              db.collection('users').doc(user.uid).set({
                email,
                displayName: name,
                provider: 'email',
                ticketId: password,
                entranceType
              }, {merge: true}).then(() => {
                //dispatch(setUserPaid(true, isVIP));
                let isVIP = false
                history.replace(isVIP ? '/thank-you' : '/stream')
 
              });
              
            })
            .catch((error) => {
              setAuthError({
                type: LOGIN_ERROR_TYPE,
                message: error.message,
                code: error.code,
              });
              console.log('User create error: ', error)
            });
          return;
        }
        setAuthError({
          type: LOGIN_ERROR_TYPE,
          message: err.message,
          code: err.code
        });
      }).finally(() => {
      setLoading(false)
    })
  }

  
  

  function getPopupProps() {
    const popupProps = {
      context: '',
      position: '',
    };

    if (!_.get(authError, 'type', '') || _.get(authError, 'code', '') === 'auth/popup-closed-by-user') return null;

    switch (authError.type) {
      case LOGIN_ERROR_TYPE:
        popupProps.context = loginButtonRef;
        popupProps.position = 'bottom center';
        break;      
      default:
        popupProps.context = '';
        popupProps.position = '';
        break;
    }

    return (
      <Popup
        className='popup-error'
        {...popupProps}
        open={_.get(authError, 'type', '') ? true : false}
        content={_.get(authError, 'message', '')}
      />
    );
  }

  return (
    <div className='login-wrapper'>

      {getPopupProps()}

      <div className='login-dialog'>
        <div className='social-controls'>
        <p></p>
        {/*
          <div className='login-logo'/>
          
          <img src="https://livestreamplanet.enterdev.co/uploads/events/6052a941729c3321679791.jpg" width="300" />
         */}
         <img src="/white_logo.svg" width="300" />
          {userSignedInError ? (
            <div className='popup-error signed-in-error'>
              <div className='warning-icon-signed-in-wrapper'>
                <div className='warning-icon-signed-in'/>
              </div>
              <div>User already signed in with another device</div>
            </div>
          ) : null}
        </div>

        <div className='divider-controls'>
          
        </div>


        <div className='login-form'>
          <h2>Log in</h2>

          <Form>
            <Form.Input
              id='name'
              name='name'
              icon='user'
              iconPosition='left'
              placeholder='Name'
              type='text'
              value={formik.values.name}
              onChange={(e) => {
                formik.handleChange(e);
                formik.setFieldError('name', '');
                setAuthError({});
              }}
              onBlur={formik.handleBlur}
              error={!!formik.errors.name ? formik.errors.name : false}
            />

            <Form.Input
              id='password'
              name='password'
              icon='lock'
              iconPosition='left'
              placeholder='Ticket Password'
              type='password'
              value={formik.values.password}
              onChange={(e) => {
                formik.handleChange(e);
                formik.setFieldError('password', '');
                setAuthError({});
              }}
              onBlur={formik.handleBlur}
              error={!!formik.errors.password ? formik.errors.password : false}
            />

            <Ref innerRef={loginButtonRef}>
              <Button
                loading={loading}
                color='teal'
                fluid size='large'
                className='login-button'
                onClick={loginThroughTicket}
              >
                JOIN
              </Button>
            </Ref>
          </Form>
        </div>
      </div>

   
    </div>
  )
};

export default withRouter(LogIn)
