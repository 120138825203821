import React, {useEffect, useRef, useState} from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import {withRouter} from 'react-router';
import {useDispatch, useSelector} from 'react-redux';
import ProtectedRoute from '../ProtectedRoute/ProtectedRoute';
import LogIn from '../LogIn/LogIn';
import Payment from '../Payment/Payment';
import Stream from '../Stream/Stream';
import Home from '../Home/Home';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import ThankYou from '../ThankYou/ThankYou';
import {firebaseApp, db, realTimeDb} from '../../firebase';
import {setUserInfo} from '../../store/reducers/UserReducer';
import SupportForm from '../SupportForm/SupportForm';
import './App.css';
import StreamVip from '../StreamVip/StreamVip';
import NotFoundEvent from '../NotFoundEvent/NotFoundEvent';
import TicketEvent from '../TicketEvent/TicketEvent';



const App = ({location}) => {
    const {userLoggedIn, entranceType, userPaid, userPaidForVIP} = useSelector(state => state.user);
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();
    const componentInitialized = useRef(false);
    const userAlreadyOnlineRef = useRef(false);


    const mainStyle = { 
        "background-color": "#000",
        /*display: block; 
        position: absolute; 
        top: 0px; 
        left: 0px; 
        width: 100%; 
        height: 100%; 
        z-index: -1; 
        opacity: .9; 
        background: url(/bg_main_shadow-min.jpg) no-repeat center center fixed; 
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        background-attachment: fixed;*/
    };

    useEffect(() => {
        firebaseApp.auth().onAuthStateChanged((user) => { 
     
                if (user) {
                    
                    //if (user.emailVerified) {
                    if (1 == 1) {
                      
                        const currentUserRef = realTimeDb.ref('users/' + user.uid);

                        currentUserRef.once('value').then((snapshot) => {
                           
                            const userAlreadyOnline = (snapshot.val() && snapshot.val().online) || false;
                            userAlreadyOnlineRef.current = userAlreadyOnline
                            if (!componentInitialized.current && !userAlreadyOnline) {
                                currentUserRef.set({online: true});
                                
                            }
                            componentInitialized.current = true
                        })

                        realTimeDb.ref('users/' + user.uid).onDisconnect().set({online: false})
                    } else {
                        setLoading(false)
                        return
                    }

                    db.collection('users').doc(user.uid).get().then((userSnapshot) => {
                        const userData = userSnapshot.data();

                        dispatch(setUserInfo({
                            ...userData,
                            email: user.email,
                            displayName: user.displayName,
                            userLoggedIn: true,
                            event_id: user.event_id,
                            entranceType: entranceType || userData.entranceType,
                        }));
                        setLoading(false);
                    });

                } else {
                   
                    dispatch(setUserInfo({
                        displayName: '',
                        email: '',
                        userPaid: false,
                        userLoggedIn: false,
                        entranceType: entranceType,
                        vipInvitationSent: false
                    }));
                    setLoading(false);
                }
            }
        );
    }, []);

    function redirectUser() {
        //const firebaseEmailVerify = firebaseApp.auth().currentUser && firebaseApp.auth().currentUser.emailVerified;
        console.log("1-userPaid=" + userPaid)
        console.log("2-userLoggedIn=" + userLoggedIn)
        console.log("3-userPaidForVIP=" + userPaidForVIP)

        if (userLoggedIn && userPaid &&  !userAlreadyOnlineRef.current) {
            if (userPaidForVIP) {
                console.log("3-VIP=" + userPaidForVIP)
                //console.log('redirect')
                return <Redirect to='/streamvip'/>
            } else {
                return <Redirect to='/stream'/>
            }
        } else if (userLoggedIn && !userPaid &&  !userAlreadyOnlineRef.current) {
            return <Redirect to='/login'/>
        } else {
            return <Home/>
        }
    }

    function redirectUserFromLogin() {
        //const firebaseEmailVerify = firebaseApp.auth().currentUser && firebaseApp.auth().currentUser.emailVerified;
        
        console.log("userPaid=" + userPaid)
        console.log("userLoggedIn=" + userLoggedIn)
        console.log("userPaidForVIP=" + userPaidForVIP)

        //if (userLoggedIn && userPaid && firebaseEmailVerify && !userAlreadyOnlineRef.current) {
        if (userLoggedIn && userPaid && !userAlreadyOnlineRef.current) {
            if (userPaidForVIP) {
                //return <Redirect to='/streamvip'/>
            } else {
                return <Redirect to='/stream'/>
            }
        
        } else {
            return <LogIn/>
        }
    }

    if (loading) {
        return (<div></div>)
    }

    return (
        <div className={`app-container ${location.pathname !== '/' ? 'blur-bg' : ''}`}>
            {userLoggedIn && location.pathname !== '/' && <Header/>}
            <SupportForm/>
            <Switch>
                <Route path='/' render={redirectUser} exact/>
                <Route path='/login' render={redirectUserFromLogin} exact/>
                <Route path='/not-found' component={NotFoundEvent} exact/>
                <Route path='/event/' render={redirectUserFromLogin} exact/>
                <Route path='/event/:slug' component={TicketEvent} exact/>
                <Route path='/event/:slug/:pass' component={TicketEvent} exact/>
                <ProtectedRoute authenticated={userLoggedIn} path='/payment' component={Payment} exact/>
                <ProtectedRoute authenticated={userLoggedIn} path='/stream' component={Stream} exact/>
                <ProtectedRoute authenticated={userLoggedIn} path='/thank-you' component={ThankYou} exact/>
                <ProtectedRoute authenticated={userLoggedIn} path='/:id/thank-you' component={ThankYou} exact/>
                <ProtectedRoute authenticated={userLoggedIn} path='/streamvip' component={StreamVip} exact/>
                
                {/*
                <Route path='/:id' component={StreamVip} exact/>
                */}
                <Route render={() => <Redirect to='/'/>} path='*'/>
            </Switch>
            <Footer/>
        </div>
    );
}



export default withRouter(App);
