import React, {useState, useEffect} from 'react';
import {useFormik} from 'formik';
import {Button} from 'semantic-ui-react';
import {firebaseApp, db} from '../../firebase';
import {SupportSchema} from './ValidationSchema';
import './SupportForm.css';

const SupportForm = ({}) => {
  const [loading, setLoading] = useState(false);
  const [sendingSuccess, setSendingSuccess] = useState(false);
  const [showSupportForm, setShowSupportForm] = useState(false);

  useEffect(() => {
    if(!showSupportForm) {
      setSendingSuccess(false);
    }
  }, [showSupportForm])

  const formik = useFormik({
    initialValues: {
      email: '',
      message: ''
    },
    validationSchema: SupportSchema
  });

  function saveSupportTicket() {
    setSendingSuccess(false);
    if (Object.keys(formik.errors).length) {
      return
    }

    setLoading(true);
    const {email, message} = formik.values;
    let uid = '';

    const user = firebaseApp.auth().currentUser;

    if (user && user.uid) {
      uid = user.uid
    }
    
    db.collection('support').add({
      email,
      message,
      uid
    }).then(() => {
      setSendingSuccess(true);
      setLoading(false);
      formik.resetForm();
    });
    

    setTimeout(() => setShowSupportForm(false), 5000);
  }

  if (!showSupportForm) {
    return (
      <div className='mail-icon-block'
           style={{display: !showSupportForm ? 'flex' : 'none'}}
           onClick={() => setShowSupportForm(!showSupportForm)}
      >
        <div>Contact support</div>
        <div className='mail-icon'/>
      </div>
    );
  }

  console.log('sendingSuccess ->', sendingSuccess)

  return (
    <div className='support-bg'>
      <div className='support-wrapper'>
        <div className='support-form'>
          <div className='support-close-icon' onClick={() => setShowSupportForm(false)}/>

          {sendingSuccess ? (
            <div className='support-sending-success'>
              <div className='support-icon-success'/>
              <div className='support-sending-text'>Thank you!</div>
              <div className='support-sending-desc'>Your letter has been sent to support. Please wait for answer to your
                email.
              </div>
            </div>
          ) : (
            <div className='form-block'>
              <div className='form-group'>
                <label htmlFor='email'>Your Contact Email</label>
                <input
                  id='email'
                  type='email'
                  name='email'
                  value={formik.values.email}
                  className='form-control'
                  // placeholder='Email'
                  required
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.email && formik.errors.email ? formik.errors.email : ''}
                />
                {formik.touched.email && formik.errors.email ? (
                  <div className='support-form-error'>{formik.errors.email}</div>) : null}
              </div>
              <div className='form-group'>
                <label htmlFor='message'>Your Message</label>
                <textarea
                  id='message'
                  rows='7'
                  type='text'
                  name='message'
                  value={formik.values.message}
                  className='form-control'
                  // placeholder='Message text'
                  required
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={!!formik.errors.message ? formik.errors.message : ''}
                />
                {formik.touched.message && formik.errors.message ? (
                  <div className='support-form-error'>{formik.errors.message}</div>) : null}
              </div>
              <Button disabled={!formik.values.email || !formik.values.message}
                      loading={loading}
                      onClick={saveSupportTicket}
                      className='button-send'>SEND</Button>
            </div>
          )}
        </div>
      </div>
    </div>
  )
};

export default SupportForm;
