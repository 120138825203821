import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/database';
import 'firebase/analytics'
//const firebase = require("firebase-admin");
import "firebase/functions";


const firebaseConfig = {
    apiKey: "AIzaSyCtUUkf631ezT89A3HdcwHm5GG_Z6qzrnA",
    authDomain: "sceenicpaywall.firebaseapp.com",
    databaseURL: "https://sceenicpaywall.firebaseio.com",
    projectId: "sceenicpaywall",
    storageBucket: "sceenicpaywall.appspot.com",
    messagingSenderId: "1036972148040",
    appId: "1:1036972148040:web:57d218d708162cf9559f7d",
    measurementId: "G-2JWQZDMXRL"
};

export const firebaseApp = app.initializeApp(firebaseConfig);
//export const firebaseAppAdmin = firebase.initializeApp(firebaseConfig, 'admin');
app.analytics();

export const db = firebaseApp.firestore();

export const realTimeDb = firebaseApp.database();

export const firebaseFunctions = firebaseApp.functions();


/**
export const googleProvider = new app.auth.GoogleAuthProvider();
googleProvider.addScope('email');

export const facebookProvider = new app.auth.FacebookAuthProvider();
**/