import React from 'react';
import './Footer.css';

const Footer = ({}) => {
    return (
        <div className='footer-wrapper'>
            <div className='powered-by'>
                Powered by
            </div>

            <a target='_blank' href='http://arsls.com'>
                <div className='ar-solutions'>
                    <div className='ar-logo'/>
                </div>
            </a>

            <div style={{padding: '0 5px'}}>
                &
            </div>

            <a target='_blank' href='https://www.enterdev.com'>
                <div className='sceenic'> Enterdev
                </div>
            </a>
        </div>
    )
};

export default Footer;
