import React, { } from 'react';
import {withRouter} from 'react-router';
import './NotFoundEvent.css';
import {Redirect, useHistory} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {setEntranceType} from '../../store/reducers/UserReducer';


const NotFoundEvent = ({}) => {
    
    const history = useHistory();
     const dispatch = useDispatch();
    
    function selectEntrance(type) {
        dispatch(setEntranceType(type))
        history.push('/login')
    }
    
    
    return(
    <div className='home-content'>
      <div className='dialog-wrapper'>
        <div className='logo-wrapper'>
          <div className='home-logo'/>
        </div>

        <div className='home-header'>
          EVENT NOT FOUND
        </div>

        <div className='home-description'>
          Please verify your data and log in again
        </div>

        <div className='home-buttons'>
          

          <button className='vip' onClick={()=> selectEntrance("general")}>Login</button>
        

        </div>
      </div>
    </div>
  )

        
      
   
};


export default withRouter(NotFoundEvent);