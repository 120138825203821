import React, {useState} from 'react';
import {withRouter} from 'react-router';
import {Redirect, useParams} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {setEntranceType} from '../../store/reducers/UserReducer';
import {setEntranceParams} from '../../store/reducers/UserReducer';
import {ENTRANCE_TYPE} from '../../constants';
import './TicketEvent.css';

const TicketEvent = (props) => {
    const dispatch = useDispatch();
    //const [paramSettings, setparamSettings] = useState({});
    
    function selectEntrance(type,slug,pass) {
        dispatch(setEntranceType(type))
        dispatch(setEntranceParams(slug,pass))
        //history.push('/login')
    }
  
    //console.log(props.match.params);
    const pass = (props.match.params.pass)?props.match.params.pass:null;
    const slug = (props.match.params.slug)?props.match.params.slug:null;

    if (!slug){
        return (
             <Redirect to='/'/>
        )
    }else{
        selectEntrance('general',slug,pass)
        return <Redirect to='/login'/>
    }
}

export default withRouter(TicketEvent);