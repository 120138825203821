import * as Yup from 'yup';

const EMAIL_MIN_LENGTH = 6;
const EMAIL_MAX_LENGTH = 70;
const PASSWORD_MIN_LENGTH = 6;
const PASSWORD_MAX_LENGTH = 70;


export const LoginSchema = Yup.object().shape({
  name: Yup.string().trim()
    .required('Name is Required')
    .min(EMAIL_MIN_LENGTH, `Name too short - Min length is ${EMAIL_MIN_LENGTH} characters`)
    .max(EMAIL_MAX_LENGTH, `Name too long - Max length is ${EMAIL_MAX_LENGTH} characters`)
    ,

  password: Yup.string().trim()
    .required('Password is Required')
    .min(PASSWORD_MIN_LENGTH, 'Password too short')
    .max(PASSWORD_MAX_LENGTH, `Password too long - Max length is ${PASSWORD_MAX_LENGTH} characters`),
});

/**
export const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .required('Email is Required')
    .min(EMAIL_MIN_LENGTH, `Email too short - Min length is ${EMAIL_MIN_LENGTH} characters`)
    .max(EMAIL_MAX_LENGTH, `Email too long - Max length is ${EMAIL_MAX_LENGTH} characters`)
    .test('isValid', 'Please enter a valid email', async function (val) {
      // /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(val)) return true;

      return false;
    }),

  password: Yup.string().trim()
    .required('Password is Required')
    .min(PASSWORD_MIN_LENGTH, 'Password too short')
    .max(PASSWORD_MAX_LENGTH, `Password too long - Max length is ${PASSWORD_MAX_LENGTH} characters`),
});
**/