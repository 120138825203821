import React, {Fragment, useEffect,useState,useRef} from 'react';
import {useSelector} from 'react-redux';
import {withRouter} from 'react-router';
import {useParams} from 'react-router-dom';
import {db,firebaseApp, realTimeDb,firebaseFunctions} from '../../firebase'

import './StreamVip.css'

const StreamVip = ({history}) => {
    let { displayName, email,entranceType } = useSelector(state => state.user);
    const user = firebaseApp.auth().currentUser;
    const [userSettings, setUserSettings] = useState({});
    const [roomIsRunning, setroomIsRunning] = useState(false);
    const {uid} = user;
    let meetingId   = "" 
    const isMounted = useRef(true);


   /**
   useEffect(()=>{

        if (isMounted.current) {  
         db.collection('users').doc(uid).get().then(async (userSnapshot) => {
           if(userSnapshot.exists){                
                eventInfo =  userSnapshot.data();
                //setUserSettings(userSnapshot.data());
                meetingId   = eventInfo.event_id
                if (meetingId == 'undefined'){
                    history.replace('/login')
                }                
                attendedPWD = meetingId + "attendee"
                displayName = eventInfo.displayName
                //isRunning = api.monitoring.isMeetingRunning(meetingId);
                //isStarted();
                await validateRoom()
            }else{
                history.replace('/not-found');
            }
        });
        return () => {
        isMounted.current = false;
      };
        
    }
        
                    
    }, []);
     * 
    */
   
   useEffect(()=>{

        if (isMounted.current) {  
            validateRoom()
            return () => {
            isMounted.current = false;
          };
        
    }
        
                    
    }, []);
    
    async function validateRoom(){    
        let  playervip = firebaseFunctions.httpsCallable('playervip');
            await playervip({ meetingId: meetingId })
              .then(async (result) => {
                // Read result of the Cloud Function.
                var request = result.data;
                console.log(request)
                if (request.success){
                    setroomIsRunning(true)
                    setTimeout(() => redirect(request.url), 3000);
                }
 
              })
           .catch((error) => {
                console.log(error)
          }); 
    }
    
    //redirect to player
    function redirect(url){
        console.log('==== redirect =====')
        return false
        firebaseApp.auth().signOut()
        .then(function() {
            window.location = url;
        })
        .catch(function(error) {
            window.location = url;
        });
    }
    
      


     
   return (
        
   
        <Fragment>
            <div className='thank-you-content'>
             {roomIsRunning === true ? (
                <div className='thank-you-description'>Redirecting, one moment please ...</div>
             ):(
              <div className='thank-you-description'>Event not started...</div>
            )}
            </div>
        </Fragment>
 )
}
export function useIsMounted() {
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;
    return () => isMounted.current = false;
  }, []);

  return isMounted;
}

export default withRouter(StreamVip)