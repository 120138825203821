import React from 'react';
import {withRouter} from "react-router";
import {firebaseApp, realTimeDb} from "../../firebase";
import './Header.css';

const Header = ({history}) => {

  function signOut() {
    const uid = firebaseApp.auth().currentUser.uid;
    firebaseApp.auth().signOut().then(() => {
      realTimeDb.ref('users/' + uid).set({
        online: false
      })
      //history.replace('/')
      document.location = '/login'
    })
  }

  return (
    <div className='header-wrapper'>
      <div className='header-logo-block'>
        <div className='header-logo'/>
      </div>

      <div className='logout-wrapper' onClick={signOut}>
        <div className='logout-icon-block'>
          <div className='logout-icon'/>
        </div>
        <div>Salir</div>
      </div>
    </div>
  )
};

export default withRouter(Header);
