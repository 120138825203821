import * as Yup from "yup";

export const SupportSchema = Yup.object().shape({
    email: Yup.string()
        .email('Invalid email')
        .required('Email Required'),

    message: Yup.string().trim()
        .required('Message is Required')
        .min(2, 'Message too short')
        .max(1000, 'Message too long'),
});
